<p-tree
    [value]="treeData"
    [(selection)]="selectedFeatures"
    selectionMode="checkbox"
    (onNodeSelect)="nodeSelect($event)"
    (onNodeUnselect)="onNodeUnselect($event)"
    [propagateSelectionUp]="false"
>
    <ng-template let-node pTemplate="default">
        <span class="mr-3">{{ node.label }}</span>
        <p-inputNumber 
            *ngIf="node.data.inputType.name === 'SINGLE_LINE_STRING' && node.data.inputType.validator.name === 'NUMERIC'"
            [(ngModel)]="node.value"
            (change)="onInputChange(node)"
            [attr.name]="node.data.name"
            mode="decimal" 
            [min]="0"
            [max]="10000"
            [useGrouping]="false"
            [showButtons]="true"
        >
        </p-inputNumber>
        <input
            *ngIf="node.data.inputType.name === 'SINGLE_LINE_STRING' && node.data.inputType.validator.name !== 'NUMERIC'"
            pInputText
            type="text"
            [attr.name]="node.data.name"
            [(ngModel)]="node.value"
            (change)="onInputChange(node)"
        />
        <p-dropdown 
            *ngIf="node.data.inputType.name === 'COMBOBOX'"
            styleClass="feature-p-dropdown"
            [attr.name]="node.data.name"
            [options]="node.data.inputType.itemSource.items" 
            [(ngModel)]="node.value"
            optionLabel="displayText"
            optionValue="value"
            [filter]="true" 
            filterBy="displayText"
            appendTo="body"
        >
        </p-dropdown>
        <!-- <select
            *ngIf="node.data.inputType.name === 'COMBOBOX'"
            [(ngModel)]="node.value"
            (change)="onDropdownChange(node)"
        >
            <option *ngFor="let item of node.data.inputType.itemSource.items" [value]="item.value">
                {{ item.displayText }}
            </option>
        </select> -->
    </ng-template>
</p-tree>
