<div class="my-2">
    <input
        type="text"
        pInputText
        (input)="filterPermissions($event)"
        [(ngModel)]="filter"
        placeholder="{{ 'SearchWithThreeDot' | localize }}"
    />
</div>
<p-tree
    [value]="treeData"
    [(selection)]="selectedPermissions"
    selectionMode="checkbox"
    (onNodeSelect)="nodeSelect($event)"
    (onNodeUnselect)="onNodeUnselect($event)"
    [propagateSelectionUp]="false"
    [propagateSelectionDown]="!disableCascade"
></p-tree>
