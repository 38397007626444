import { Injector, ElementRef } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
    LocalizationService,
    PermissionCheckerService,
    FeatureCheckerService,
    NotifyService,
    SettingService,
    MessageService,
    AbpMultiTenancyService
} from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { SubscribableEditionType } from './service-proxies/service-proxies';
import { PrimengTableHelper } from '@shared/helpers/PrimengTableHelper';
import { FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidateFormGroupErrorMessage {
    errorKey: string;
    customErrorMsg: string;

    constructor(errorKey: string, customErrorMsg: string) {
        this.errorKey = errorKey;
        this.customErrorMsg = customErrorMsg;
    }
}

export abstract class AppComponentBase {

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    primengTableHelper: PrimengTableHelper;

    SubscribableEditionType = SubscribableEditionType;

    scrollable = false;

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.primengTableHelper = new PrimengTableHelper();
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isNumber(value: string | number): boolean {
        return value != null && value !== '' && !isNaN(Number(value.toString()));
    }

    validateFormGroup(
        form: FormGroup,
        errorCount: number = 0,
        errorKey: string = '',
        prefixToRemove: string = '',
        customErrorMsgs: CustomValidateFormGroupErrorMessage[] = [],
        scrollToErrorElement: boolean = false
    ): boolean {
        Object.keys(form.controls).forEach((key) => {
            form.get(key).markAsDirty();

            const controlErrors: ValidationErrors = form.get(key).errors;

            if (controlErrors != null) {
                errorKey = key;
                errorCount++;

                if (scrollToErrorElement) {
                    let targetElement = document.getElementById(key);
                    window.scroll({ top: targetElement.offsetTop, behavior: 'smooth' });
                    scrollToErrorElement = false;
                }
            }
        });

        if (!form.valid || errorCount > 0) {
            if (errorCount === 1) {
                errorKey = errorKey.replace(/[0-9]/g, '');
                errorKey = errorKey.replace('Id', '');

                if (this.NotNullOrEmpty(prefixToRemove)) {
                    errorKey = errorKey.replace(prefixToRemove, '');
                }

                let customErrorMsg = customErrorMsgs.find((x) => x.errorKey === errorKey)?.customErrorMsg;

                this.notify.error(
                    this.NotNullOrEmpty(customErrorMsg) ? customErrorMsg : this.l(errorKey) + ' is required'
                );
            } else {
                this.notify.error(this.l('FillOutAllRequiredFieldsWarningMessage'));
            }

            return false;
        }

        return true;
    }

    NotNullOrEmpty(str): boolean {
        if (str != '' && str != undefined && str != null) {
            return true;
        } else {
            return false;
        }
    }
}
