<button class="btn btn-secondary w-100" (click)="openPermissionTreeModal()" *ngIf="!dontAddOpenerButton">
    {{ 'SelectPermissions' | localize }} ({{ NumberOfFilteredPermission }})
</button>

<div
    appBsModal
    #permissionTreeModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="permissionTreeModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg permissionFilterTreeModal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ 'SelectPermissions' | localize }}
                </h4>
                <button type="button" class="btn-close" (click)="closePermissionTreeModal()" [attr.aria-label]="l('Close')">
                </button>
            </div>
            <div class="modal-body">
                <permission-tree
                    #permissionTree
                    [singleSelect]="singleSelect"
                    [disableCascade]="disableCascade"
                ></permission-tree>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary fw-bold" (click)="closePermissionTreeModal()">
                    {{ 'Select' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
