import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToArray'
})
export class EnumToArraySelectionPipe implements PipeTransform {
    transform(data: Object) {
        var keys = Object.keys(data);
        var d = keys.slice(keys.length / 2);
        //d.unshift('All');
        return d;
    }
}