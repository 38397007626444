import { IAjaxResponse } from "abp-ng2-module";

import {FileUploader, FileUploaderOptions, FileItem} from 'ng2-file-upload';

export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static openIddictUrl: string; // OpenIddict OAuth Url
    static openIddictScopes: string; // OpenIddict OAuth Scopes
    static openIddictClientId: string; // OpenIddict Client Id
    static recaptchaSiteKey: string;
    static uiAvatarsBaseUrl: string;

    static readonly authentication = {
        tempUsername: 'Abp.TempUserName',
        tempPassword: 'Abp.TempPassword',
        verifyEmail: 'Abp.VerifyEmail',
        openiddict: {
            enabled: true,
            stateKey: 'Abp.OpenIddict.State',
            invitationCodeKey: 'Abp.MultiTenancy.InvitationCode'
        }
    }
    
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'TanahMu'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static readonly DATE_FORMAT = 'YYYY-MM-DD HH:mm';
    static readonly MOMENT_FORMAT = 'yyyy-MM-dd HH:mm'; //luxon
    static readonly DATE_TIMEPICKER = 'YYYY-MM-DDTHH:mm:ssZ';
    static readonly VERSION_FORMAT = 'yyyyMMdd HH:mm'; //luxon


    static imageUpload(files, editor, welEditable) {
        var uploader: FileUploader;
        var _uploaderOptions: FileUploaderOptions = { url: AppConsts.remoteServiceBaseUrl + '/FileUpload/UploadFile' };
        uploader = new FileUploader({url: AppConsts.remoteServiceBaseUrl + '/FileUpload/UploadFile'});
        _uploaderOptions.autoUpload = false;
        //_uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        uploader.addToQueue(files);
        _uploaderOptions.removeAfterUpload = true;
        uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };
    
        uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            console.log("Building");
            //form.append('Description', this.description);
        };
    
        uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                //this.notify.success(this.l("FileSavedSuccessfully", response));
                editor.insertImage(welEditable, resp.result);
            } else {
                //this.notify.error(resp.error.message);
            }
        };
    
        uploader.setOptions(_uploaderOptions);
        uploader.uploadAll();
      }

    static StringIsNumber = value => isNaN(Number(value[0])) === false;

    static GaugeColorSet1 = [
        "rgba(255,0,0,.6)",
        "rgba(255,0,0,.4)",
        "rgba(127,0,127,.3)",
        "rgba(0,0,255,.4)",
        "rgba(0,0,255,.6)",
    ]

    static getProjectTaskStatusLabelType(status, prefix = "badge") {
        switch(status) {
            case 0:
                return prefix+"-secondary";
            case 1:
                return prefix+"-primary";
            case 2: 
                return prefix+"-success";
            default:
                return prefix+"-warning";
        }
    }


    static getFirstLetterWords(text) {
        var charBuffer = "";
        var s = text.split(' ');
         
        s.forEach(element => {
            charBuffer+=element[0];
        });
         
        return charBuffer;
    }

    static getStackedBadge(title, subtitle, type = 'primary', additionaClass = ''){
        return "<span class=\"stacked-badge  badge badge-"+type+" "+additionaClass+"\">"+
        "<span class=\"stacked-label\">"+subtitle+"</span><span class=\"d-block d-sm-inline-block stacked-title badge badge-light\">"+title+"</span>"+
        "</span>";
    }

    static getDaysBetweenDate(start: Date, end: Date) {
        
        let days = Math.floor((new Date(end).getTime() - new Date(start).getTime()) / 1000 / 60 / 60 / 24 * 10)/10;
        return days;
        
    }

    static isImage(type) {
        return (type === 'jpg' || type === 'png' || type === 'jpeg' || type === 'gif' || type === 'svg');
    }

    static prepareImageUpload(projectId = '') {
        return {
          enableDragAndDropFileToEditor: true,
          uploader: {
              url: AppConsts.remoteServiceBaseUrl + '/JoditUpload/' + projectId,
              prepareData: function (data) {
                return data;
              },
              isSuccess: function (resp) {
                  return resp;
              },
              process: function (resp) {
                  return {
                      files: resp.result.files
                  };
              },
              error: function (e) {
                    abp.notify.error(e.getMessage());
              },
              defaultHandlerSuccess: function (data) {
                  let i, field = 'files';
                  if (data[field] && data[field].length) {
                      for (i = 0; i < data[field].length; i += 1) {
                          const type = AppConsts.getFileType(data.files[i]);
                          if (type === 'jpg' || type === 'png' || type === 'jpeg' || type === 'gif' || type === 'svg') {
                                this.selection.insertImage(data.files[i]);
                          } else {
                                this.selection.insertHTML(' <a href="' + data.files[i] + '" target="_blank">' +
                                AppConsts.getFileName(data.files[i]) + '</a> ');
                          }
                      }
                  }
              },
          }
      };
    }

    static getFileType(filename) {
        return filename.split('.').pop();
    }
    static getFileName(filename) {
        return filename.split('/').pop();
    }
}