import { Pipe, PipeTransform } from '@angular/core';



@Pipe({ name: 'disasterGroupBy' })
export class DisasterGroupByPipe implements PipeTransform {

    transform(value: Array<any>, field: string): Array<any> {

        if (!value) {
            return value;
        }
        console.log(value);
        const groupedObj = value
            .sort((a, b) => a.sensorID - b.sensorID) // sort by sensorID
            .reduce((prev, cur) => {
                if (!prev[cur[field]]) {
                    prev[cur[field]] = [cur];
                } else {
                    prev[cur[field]].push(cur);
                }

                return prev;
            }, {});
        console.log('groupedObj:', groupedObj); // add this line to debug the grouping
        return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
    }
}
