import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { SplitCamelCasePipe } from '@shared/pipes/split-camel-case.pipe';
import { UnsafeHtmlPipe } from '@shared/pipes/unsafe-html.pipe';
import { EnumToArraySelectionPipe } from '@shared/pipes/enum-to-array.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';

import { GroupByPipe } from '../shared/pipes/group-by.pipe';
import { DisasterGroupByPipe } from '../shared/pipes/disaster-group-by.pipe';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppLocalizationService } from './localization/app-localization.service';
import { DateTimeService } from './utils/datetime.service';

import { FileDownloadService } from './utils/file-download.service';
import { LocalStorageService } from './utils/local-storage.service';
import { ValidationMessagesComponent } from './utils/validation-messages.component';

//Scrollbar
// import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS, NgScrollbarOptions } from 'ngx-scrollbar';
// const DEFAULT_SCROLLBAR_CONFIG: NgScrollbarOptions = {
//     visibility: 'hover',
//     appearance: 'compact',
//     autoHeightDisabled: false,
// };
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppBsModalModule } from './appBsModal/app-bs-modal.module';
import { TreeDataHelperService } from "@shared/utils/tree-data-helper.service";
import { ArrayToTreeConverterService } from "@shared/utils/array-to-tree-converter.service";
import { PermissionTreeComponent } from "../app/admin/shared/permission-tree.component";
import { FeatureTreeComponent } from "../app/admin/shared/feature-tree.component";
import { PermissionTreeModalComponent } from "../app/admin/shared/permission-tree-modal.component";

import { TreeModule } from 'primeng/tree';
import { FormsModule } from "@angular/forms";

//Primeng
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { KeyFilterModule } from 'primeng/keyfilter';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        TabsModule,
        ModalModule,
        AppBsModalModule,
        TreeModule,
        FormsModule,
        PaginatorModule
    ],
    declarations: [
        ValidationMessagesComponent,
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        SplitCamelCasePipe,
        UnsafeHtmlPipe,
        EnumToArraySelectionPipe,
        BusyDirective,
        EqualValidator,
        ToggleFullscreenDirective,
        GroupByPipe,
        DisasterGroupByPipe,
        PermissionTreeComponent,
        FeatureTreeComponent,
        PermissionTreeModalComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        SplitCamelCasePipe,
        UnsafeHtmlPipe,
        EnumToArraySelectionPipe,
        BusyDirective,
        EqualValidator,
        ToggleFullscreenDirective,
        GroupByPipe,
        DisasterGroupByPipe,
        AppBsModalModule,
        PermissionTreeComponent,
        FeatureTreeComponent,
        PermissionTreeModalComponent,
    ],
    providers: [
        EnumToArraySelectionPipe,
        DateTimeService,
        LocalStorageService,
        AppLocalizationService,
        FileDownloadService,
        // {
        //     provide: NG_SCROLLBAR_OPTIONS,
        //     useValue: DEFAULT_SCROLLBAR_CONFIG,
        // }
        TreeDataHelperService, 
        ArrayToTreeConverterService,
    ],schemas: [CUSTOM_ELEMENTS_SCHEMA]  
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                LayoutStoreService
            ]
        };
    }
}
