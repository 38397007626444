import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';

@Injectable()
export class DateTimeService {

    toJsDate(date: any): Date {
        if (!date) {
            return undefined;
        }

        if (abp.clock.provider.supportsMultipleTimezone && abp.timing.timeZoneInfo.iana.timeZoneId !== 'Etc/UTC') {
            return this.adjustTimeZone(date.toDate(), abp.timing.timeZoneInfo.iana.timeZoneId);
        } else {
            return date.toDate();
        }
    }
    
    toMoment(date: Date): Moment {
        if (!date) {
            return undefined;
        }

        if (abp.clock.provider.supportsMultipleTimezone && abp.timing.timeZoneInfo.iana.timeZoneId !== 'Etc/UTC') {
            return this.changeTimeZone(date, abp.timing.timeZoneInfo.iana.timeZoneId);
        } else {
            return moment(date);
        }
    }

    changeTimeZone(date: Date, ianaTimezoneId: string): Moment {
        // only changes timezone of given date without changing the date itself
        var utcDateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
        var currentTimezoneString = moment(new Date()).tz(ianaTimezoneId).format('ZZ');
        var dateStringWithoutTimezome = utcDateString.substring(0,utcDateString.length-1) + currentTimezoneString;
        return moment.utc(dateStringWithoutTimezome);
    }

    adjustTimeZone(date: any, ianaTimezoneId: string): Date {
        // Adjust to user local timezone for primeng
        // only changes timezone of given date without changing the date itself
        let formattedDateString = moment(new Date()).tz(ianaTimezoneId).format();

        // Find the index of the first occurrence of '+'
        let index = formattedDateString.indexOf('+');

        // If '+' is not found, then try for '-'
        index = index === -1 ? formattedDateString.lastIndexOf('-') : index;

        // If '+' or '-' is found, extract substring up to that index
        formattedDateString = index !== -1 ? formattedDateString.substring(0, index) : formattedDateString;

        // Get local timezone
        const localTimezone = moment.tz.guess();
        let currentTimezoneString = moment(new Date()).tz(localTimezone).format('ZZ');       

        // Append local timezone to adjusted time
        let dateStringWithoutTimezome = formattedDateString + currentTimezoneString;

        return moment.utc(dateStringWithoutTimezome).toDate();
    }
}